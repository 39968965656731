import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';
import { Navigation, Scrollbar, FreeMode, Autoplay, Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import 'swiper/css/grid';
import 'swiper/css/autoplay';

export default class extends Controller {
  static targets = ['slider', 'progress', 'prev', 'next'];
  static values = {
    options: Object
  };

  declare sliderTarget: HTMLElement;
  declare hasProgressTarget: boolean;
  declare progressTarget: HTMLElement;
  declare hasPrevTarget: boolean;
  declare hasNextTarget: boolean;
  declare prevTarget: HTMLElement;
  declare nextTarget: HTMLElement;
  declare optionsValue: SwiperOptions;

  connect() {
    const swiperParams: SwiperOptions = {
      ...this.defaultOptions,
      ...this.optionsValue,
      navigation: {
        ...this.defaultOptions.navigation,
        ...this.optionsValue.navigation
      },
      scrollbar: {
        ...this.defaultOptions.scrollbar,
        ...this.optionsValue.scrollbar
      }
    };

    this.instance = new Swiper(this.sliderTarget, swiperParams);
  }

  get hasNavigation() {
    return this.hasPrevTarget && this.hasNextTarget;
  }

  get defaultOptions(): SwiperOptions {
    return {
      cssMode: false,
      resistanceRatio: 0.65,
      slidesPerView: 1.3,
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24,
      spaceBetween: 12,
      modules: [Navigation, Scrollbar, FreeMode, Autoplay, Grid],
      navigation: {
        enabled: this.hasNavigation,
        nextEl: this.hasNextTarget ? this.nextTarget : undefined,
        prevEl: this.hasPrevTarget ? this.prevTarget : undefined
      },
      scrollbar: {
        enabled: this.hasProgressTarget,
        el: this.hasProgressTarget ? this.progressTarget : undefined,
        hide: false
      }
    };
  }
}
